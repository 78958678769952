import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout'
import SEO from 'components/shared/SEO'

import Hero from 'components/layout/Sections/Hero'
import About from 'components/layout/Sections/Pages/Innovations/About'
import Tiles from 'components/layout/Sections/Pages/Innovations/Tiles'
import InfoWithButton from 'components/layout/InfoWithButton'

import Navigation from 'components/layout/Navigation/index'

const breadcrumbs = {
  mobile: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Innowacje',
    },
  ],
  desktop: [
    {
      label: 'Home',
      link: '/',
    },
    {
      label: 'Innowacje',
    },
  ],
}

const InnowacjePage: React.FC<PageProps> = ({ data }) => {
  const PAGE = data?.wpPage?.innowacje
  const PAGE_SEO = data?.wpPage?.seo

  const INNOVATIONS_GALLERY = React.useMemo(
    () =>
      PAGE?.innovationsTiles?.map((tile) => ({
        img: tile?.innovationsTileImg?.localFile?.childImageSharp
          ?.gatsbyImageData,
        alt: tile?.innovationsTileImg?.altText,
        label: tile?.innovationsTileTitle,
        link: `${tile?.innovationsTileUrl}/`,
      })),
    []
  )

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <Hero
        imgMobile={
          PAGE?.innovationsImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        imgDesktop={
          PAGE?.innovationsImg?.localFile?.childImageSharp?.gatsbyImageData
        }
        headingMobile={PAGE?.innovationsTitle}
        headingDesktop={PAGE?.innovationsTitle}
        breadcrumbs={breadcrumbs}
      />
      <About
        description1={PAGE?.innovationsText}
        description1MaxWidth="340px"
        description2Title={PAGE?.innovationsTitleRight}
        description2={PAGE?.innovationsDescriptionRight}
        description2MaxWidth="820px"
      />
      <Tiles gallery={INNOVATIONS_GALLERY} />
      <InfoWithButton />
    </Layout>
  )
}

export default InnowacjePage

export const query = graphql`
  query InnowacjePage {
    wpPage(slug: { regex: "/innowacje/" }) {
      seo {
        title
        opengraphDescription
        opengraphAuthor
        metaRobotsNoindex
        metaRobotsNofollow
        metaKeywords
        metaDesc
      }
      innowacje {
        fieldGroupName
        innovationsDescriptionRight
        innovationsText
        innovationsTitle
        innovationsTitleRight
        innovationsImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        innovationsTiles {
          innovationsTileTitle
          innovationsTileUrl
          innovationsTileImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 800
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
