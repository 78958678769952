import React from 'react'
import styled, { css } from 'styled-components'

import Container from 'components/shared/container'
import { Heading, Text } from 'components/shared/typography'

import useBreakpoint from 'hooks/useBreakpoint'

type Props = {
  description1: string
  description1MaxWidth: string
  description2Title: string
  description2: string
  description2MaxWidth: string
}

const Section = styled.section`
  width: 100%;
  padding: 40px 0 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${({ theme }) => theme.media.lg.min} {
    padding: 0;
    background-color: ${({ theme }) => theme.colors.white};
  }
`

const StyledContainer = styled(Container)``

const InnerWrapper = styled.div<{ textMaxWidth: string }>`
  position: relative;
  ${({ theme }) => theme.media.lg.min} {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-left: 30px;

    & ${Text}:first-child {
      max-width: ${({ textMaxWidth }) => textMaxWidth};
    }
  }
`

const Hr = styled.div<{ hidden?: boolean }>`
  width: 80px;
  height: 8px;
  margin: 15px auto 10px auto;
  background-color: ${({ theme }) => theme.colors.primary300};
  border-radius: 3px;

  ${({ theme }) => theme.media.lg.min} {
    position: absolute;
    top: 0;
    left: 0;
    width: 8px;
    height: 100%;
    margin: 0;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`

const Description2Wrapper = styled.div<{ textMaxWidth: string }>`
  position: relative;

  ${({ theme }) => theme.media.lg.min} {
    margin-left: 40px;
    padding-left: 30px;
    max-width: ${({ textMaxWidth }) => textMaxWidth};

    &:before {
      display: block;
    }
  }
`

const About: React.FC<Props> = ({
  description1,
  description1MaxWidth,
  description2Title,
  description2,
  description2MaxWidth,
}) => {
  const { lg } = useBreakpoint()

  return (
    <Section
      title={description2Title ? description2Title.replace(/<br\/> /g, '') : ''}
    >
      <StyledContainer>
        <InnerWrapper textMaxWidth={description1MaxWidth}>
          <Text
            size={lg ? 16 : 15}
            weight={500}
            themecolor="black"
            align={lg ? 'left' : 'center'}
            dangerouslySetInnerHTML={{ __html: description1 }}
          />
          <Hr />
          <Description2Wrapper textMaxWidth={description2MaxWidth}>
            <Heading
              as="h2"
              size={lg ? 26 : 34}
              weight={600}
              align={lg ? 'left' : 'center'}
              themecolor="primary"
              dangerouslySetInnerHTML={{ __html: description2Title }}
            />
            <Text
              size={lg ? 16 : 15}
              weight={500}
              themecolor="black"
              align={lg ? 'left' : 'center'}
              dangerouslySetInnerHTML={{ __html: description2 }}
            />
            <Hr hidden={!lg} />
          </Description2Wrapper>
        </InnerWrapper>
      </StyledContainer>
    </Section>
  )
}

export default About
